import React from 'react';

import { Alert, AlertTitle, Snackbar } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';

import { uiCloseAlert } from 'reducers/uiReducer/uiActions';

export const CustomAlert = () => {
    const dispatch = useDispatch();
    const {alert} = useSelector(state => state.ui);
    
    const {open, title, severity, message} = alert;

    const handleClose = (event, reason) => {
      dispatch(uiCloseAlert());
    };

    // calculates hide duration based on the lenght of the message  
    const getHideDuration = () => {
        if (message.length < 50)
            return 4000;
        else 
            return 6000;
    }

  return (
      <Snackbar
        open={open}
        autoHideDuration={getHideDuration()}
        onClose={handleClose}
      >
        <Alert 
            severity={severity} 
            onClose={handleClose}
            sx={{ width: '100%' }}
        >
            <AlertTitle>{title}</AlertTitle>
            {message}
        </Alert>
      </Snackbar>
    )
};
