export const getBase64FromUrl = async (url) => {
    // CORS Needs to be enabled on the bucket!
    // gsutil cors set CORS_CONFIG_FILE gs://BUCKET_NAME
    const data = await fetch(url);
    const blob = await data.blob();
    return new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(blob); 
        reader.onloadend = function() {
            const base64data = reader.result;   
            resolve(base64data);
        }
    });
  }