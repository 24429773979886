export const uiTypes = {
    uiSetLanguage: '[UI] changes the active language',

    uiSetAdminActiveLink: '[UI] sets the active link on the admin section',

    uiCloseAlert: '[UI] - Hides alert message',
    uiOpenAlert: '[UI] - Shows the alert message',

    uiOpenBackdrop: '[UI] - opens the backdrop',
    uiCloseBackdrop: '[UI] - close the backdrop',

}