import React from 'react'
import PropTypes from 'prop-types';
export const FullScreen = ({children}) => {

  return (
        <div>
            <main>{children}</main>
        </div>
  )
}

FullScreen.propTypes = {
    children: PropTypes.node,
  };
