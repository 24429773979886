import {combineReducers} from 'redux';
import { adminReducer } from './adminReducer/adminReducer';
import { authReducer } from './authReducer/authReducer';
import { mintReducer } from './mintReducer/mintReducer';
import { soundReducer } from './soundReducer/soundReducer';
import { uiReducer } from './uiReducer/uiReducer';
import { walletProviderReducer } from './walletProviderReducer/walletProviderReducer';

export const rootReducer = combineReducers({
    ui: uiReducer,
    sound: soundReducer,
    walletProvider: walletProviderReducer,
    auth: authReducer,
    admin: adminReducer,
    mint: mintReducer,
})
