export const light = {
  alternate: {
    main: '#f7f9fc',
    dark: '#edf1f7',
  },
  cardShadow: 'rgba(23, 70, 161, .11)',
  mode: 'light',
  primary: {
    main: '#98DDD9',
    light: '#33CC70',
    dark: '#000000',
    contrastText: '#fff',
    aoi: '#69D2E7',
    lightPink: '#f4c2c2',
    cleanPowder: '#A7DBD8',
    beachStorm: '#E0E4CC',
    giantGoldfish: '#F38630',
    unrealFoodPills: '#FA6900',
    sandy: '#F8E6C0',
    heroTitle: '#E46824',
    heroSubTitle: '#E48B45',
    title: '#DF8139'
  },
  secondary: {
    light: '#ffb74d',
    main: '#f9b934',
    dark: '#FF9800',
    contrastText: 'rgba(0, 0, 0, 0.87)',
  },
  text: {
    primary: '#000000',
    secondary: '#69D2E7'
  },
  divider: 'rgba(0, 0, 0, 0.12)',
  background: {
    topBar: '#FFFFFF',
    paper: '#FFFFFF',
    default: '#fffff',
    level2: '#f5f5f5',
    level1: '#fff',
  },
};

export const dark = {
  alternate: {
    main: '#1a2138',
    dark: '#151a30',
  },
  cardShadow: 'rgba(0, 0, 0, .11)',
  common: {
    black: '#000',
    white: '#fff',
  },
  mode: 'dark',
  primary: {
    main: '#00AB55',
    light: '#33CC70',
    dark: '#009357',
    contrastText: '#fff',
  },
  secondary: {
    light: '#FFEA41',
    main: '#FFE102',
    dark: '#DBBE01',
    contrastText: 'rgba(0, 0, 0, 0.87)',
  },
  text: {
    primary: '#EEEEEF',
    secondary: '#AEB0B4',
  },
  divider: 'rgba(255, 255, 255, 0.12)',
  background: {
    paper: '#222B45',
    default: '#222B45',
    level2: '#333',
    level1: '#2D3748',
  },
};
