import {adminTypes} from './adminTypes';

const initialState = {
    animal: 'elephant',
    gecko: {
        accessory: [],
        background: [],
        body: [],
        companion: [],
        hat: [],
        minor1: [],
        minor2: [],
        minor3: [],
        neck: [],
        suit: [],
        surfboard: []
    },
    bear:{},
    
    traitFilter: [],
    artAmount: 0
};


export const adminReducer = (state = initialState, action) => {
    switch (action.type) {
        case adminTypes.adminAddTrait:
            return {
                ...state,
                [action.payload.animal]: {...state[action.payload.animal], [action.payload.traitType]: [...state[action.payload.animal][action.payload.traitType], action.payload.trait] }
            }

        case adminTypes.adminUpdateTrait:
                return {
                    ...state,
                    [action.payload.animal]: {...state[action.payload.animal], [action.payload.traitType]: [...state[action.payload.animal][action.payload.traitType].map(val => val.name === action.payload.trait.name ? action.payload.trait : val)] }
                }

        case adminTypes.adminSetAnimal:
            return {
                ...state,
                animal: action.payload
            }
        
        case adminTypes.adminTraitFilterAdd:
            return{
                ...state,
                traitFilter: [...state.traitFilter, action.payload]
            }
        
        case adminTypes.adminTraitFilterRemove:
            return{
                ...state,
                traitFilter: [...state.traitFilter.filter(val => val !== action.payload)]
            }

        case adminTypes.adminSetArtAmount:
            return {
                ...state,
                artAmount: action.payload
            }
        
        
        default:
            return state;
    }
}