import React, { useState, useEffect } from 'react'
import { IconButton} from '@mui/material';
import VolumeOffIcon from '@mui/icons-material/VolumeOff';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import { useDispatch, useSelector } from 'react-redux';
import { addToQueue, setNextSound } from 'reducers/soundReducer/soundAction';

const useMultiAudio = () => {
  const {selected} = useSelector(state=> state.sound);
  const dispatch = useDispatch();
  
  
  useEffect(() => {
      setSources(
        selected.map(url => {
          return {
            url,
            audio: new Audio(url.base64),
          }
        })
      )
    
    setPlayers(
      selected.map(url => {
        return {
          url,
          playing: (players[0]?.playing)  ? true : false,
        }
      })
    )
    
  }, [selected])
  
  const [sources, setSources] = useState([])

  const [players, setPlayers] = useState(
    selected.map(url => {
      return {
        url,
        playing: false,
      }
    }),
  )

  const toggle = () => {
    const newPlayers = [...players]
    players.forEach((player, i) => {
        if (player.playing)
            newPlayers[i].playing = false;
        else
            newPlayers[i].playing = true;
    })

    setPlayers(newPlayers);
  }

  useEffect(() => {
    sources.forEach((source, i) => {
      players[i].playing ? source.audio.play() : source.audio.pause()
    })
  }, [sources, players])

  useEffect(() => {
    sources[0]?.audio.addEventListener('ended', () => {
        dispatch(setNextSound());
      })
      
    return () => {
        sources[0]?.audio.removeEventListener('ended', () => {
            dispatch(setNextSound());
        })
    }
  }, [sources])


  return [players, toggle]
}

const MultiPlayer = () => {
    const {selected, sounds, queue} = useSelector(state=> state.sound);
    const dispatch = useDispatch();
    
    useEffect(() => {
        if (sounds.length > 0){
            console.log('we do have sounds');
          dispatch(addToQueue())
        }
      }, [sounds])

      useEffect(() => {
        if (selected.length == 0)
            dispatch(setNextSound())
      }, [queue])


    const [players, toggle] = useMultiAudio();

  return (
    <div>
      {(selected?.length > 0) && (
        <IconButton onClick={toggle} style={{position:'absolute', zIndex: 1, top:0, left: 0}} >
          {(players[0]?.playing) ? (
              <VolumeUpIcon style={{color: 'white'}}/>
          ) : (
              <VolumeOffIcon style={{color: 'white'}}/>
          )}
        </IconButton>
      )}
    </div>
  )
}




export default MultiPlayer