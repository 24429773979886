import React, {useEffect, useRef, useState} from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import Countdown from 'react-countdown';
import { CircularProgress} from '@mui/material';

import {db} from 'config/firebase'
import { getBase64FromUrl } from 'utils/base64';
import { loadSounds } from 'reducers/soundReducer/soundAction';
import { Box } from '@mui/system';
import MultiPlayer from 'common/Multiplayer';


const maxWaitTime = 5000;
export const NFT = () => {
  const [animal, setAnimal] = useState()
  const params = useParams();
  const dispatch = useDispatch();

  const [isError, setIsError] = useState(false)
  const [art, setArt] = useState();
  const [random, setRandom] = useState(10000000)
  const counterRef = useRef();
  const [counterMounted, setCounterMounted] = useState(false)
  const [isCounterStopped, setIsCounterStopped] = useState(true)
  
  const [animations, setAnimations] = useState([])
  const [showAnimation, setShowAnimation] = useState(false)
  const [animationToShow, setAnimationToShow] = useState();


  

  const loadAnimalArt = async () => {
    let tokenId = params.tokenId
    
    const snapshot = await db.collection('drop1').doc('nft').collection('metadata').doc(tokenId.toString()).get();
    if (!snapshot.exists) {
      setIsError(true)
      return;
    }

    const data = snapshot.data();
    setAnimal(data.animal);
    const imageBase64 = await getBase64FromUrl(data.majorImage)

    setArt({...data, imageBase64});
    // we only wait for major animation
    await loadAnimation(data.majorAnimation, data.majorAnimationDuration || 30000);
    if (data.minor1Animation)
      loadAnimation(data.minor1Animation, data.minor1AnimationDuration || 20000)

    if (data.minor2Animation)
      loadAnimation(data.minor2Animation, data.minor2AnimationDuration || 20000)

    if (data.minor3Animation)
      loadAnimation(data.minor3Animation, data.minor3AnimationDuration || 10000)
  }

  const loadAnimation = async (url, duration) => {
    try {
      const base64 = await getBase64FromUrl(url)
      setAnimations(prev => [...prev, {base64, duration}]);
    } catch ( error ){
      console.log('error fechting', error);
      setAnimations(prev => [...prev, {base64: url, duration}]);
    }
  }

  const getSounds = async () => {
    dispatch(loadSounds(animal));
  }
  
  // on load we get the animal art
  useEffect(() => {
    loadAnimalArt();
  }, [])

  useEffect(() => {
    if (animal){
      getSounds(animal)
    }
  }, [animal])
  


  
  // once we loaded the Art, we set the random and start the counter
  useEffect(() => {
    if (art && counterMounted && animations && animations.length === 1 && isCounterStopped){
      if (isCounterStopped){
        const newRandom = randomIntFromInterval(5000, maxWaitTime)
        setRandom(newRandom);
        counterRef.current.start();
        setIsCounterStopped(false);
      }
    }
  }, [art, counterMounted, animations])
  
  

  function randomIntFromInterval(min, max) { // min and max included 
    return Math.floor(Math.random() * (max - min + 1) + min)
  }

  const countdownComplete = () => {
    const index = randomIntFromInterval(0, animations.length -1);
    setAnimationToShow(animations[index]);
    setShowAnimation(true)
    counterRef.current.stop();
    setIsCounterStopped(true);
    setTimeout(() => {
      // setShowAnimation(false)np
      const newRandom = randomIntFromInterval(1000, maxWaitTime)
      setRandom(newRandom);
      counterRef.current.start();
      setIsCounterStopped(false);
    }, animations[index].duration + 15000)
  }


  const countdownRenderer = () => {
    return (<div></div>)
  }


  return (
    <div>
      {(art) ? (
        <div>
           {/* <Countdown
              date={Date.now() + random *1}
              zeroPadTime={2}
              autoStart={false}
              onMount={() => setCounterMounted(true)}
              ref={counterRef}
              onComplete={countdownComplete}
              renderer={countdownRenderer}
            /> */}
          
            <Box style={{position: 'relative'}}>
              <MultiPlayer />
              <svg
                xmlns='http://www.w3.org/2000/svg'
                viewBox={`0 0 400 400`}
                style={{position: 'relative', zxIndex: 0}}
              >
                <image
                    alt='image'
                    href={(showAnimation) ? (animationToShow.base64) : (art.imageBase64)}
                    width={'100%'}
                    height={'100%'}
                />
              </svg>
            </Box>

          </div>
      ) : (
        <div>
          {(isError) ? (
            <div className='screen-center'>
              We are unable to get this NFT. <br/> Are you sure it was minted already?
            </div>
          ) : (
            <div className='screen-center'>
              <CircularProgress/>
            </div>
          )}
        </div>

      )}
      
    </div>
  )
}

