const apiToken = process.env.REACT_APP_SAFARISURFERS_API_TOKEN;
const apiURL = process.env.REACT_APP_SAFARISURFERS_API_URL;

export const requestAuthMessage = async (address) => {
    const data = {address};
    const url = apiURL.concat('api/auth/getMessage');    
    const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': apiToken
        },
        body: JSON.stringify(data)
      });
      return response.json(); 
}

export const requestAuthToken = async (address, signature) => {
    const data = {address, signature};
    const url = apiURL.concat('api/auth/getToken');    
    
    const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': apiToken
        },
        body: JSON.stringify(data)
      });
      return response.json(); 
}

export const apiGetSounds = async (animal) => {
  const data = {animal};
  const url = apiURL.concat('api/sound');    
  
  const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': apiToken
      },
      body: JSON.stringify(data)
    });
    return response.json(); 
}

export const apiGetCurrentETHPrice = async () => {
  const defaultValue = 1510.95;

  try {
    const apiKey = process.env.REACT_APP_BLOCKCHAIN_ETHERSCAN_API;
    const url = `https://api.etherscan.io/api?module=stats&action=ethprice&apikey=${apiKey}`
    const response = await fetch(url);
    const message = await response.json()
    if (message.status === '1'){
      return Number(message.result.ethusd)
    } else {
      return defaultValue;
    }

  } catch ( error ){
    console.log(error);
    return defaultValue;
  }

}