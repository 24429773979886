import {walletProviderTypes} from './walletProviderTypes';

const initialState = {
    provider: null,
    address: null,
    networkId: null
}

export const walletProviderReducer = (state = initialState, action) => {
    switch (action.type) {
        // sets the provider, network and address once we connected with the wallet
        case walletProviderTypes.setProvider:
            return {
                ...state,
                provider: action.payload.provider,
                networkId: action.payload.network,
                address: action.payload.address
            }

        // changes the address of the wallet
        case walletProviderTypes.setAddress:
            return {
                ...state,
                address: action.payload
            }
        // changes the network of the wallet
        case walletProviderTypes.setNetworkId:
            return {
                ...state,
                networkId: action.payload
            }
        // disconnets the wallet
        case walletProviderTypes.setDisconnect:
            return {
                initialState
            }
        default:
            return state
    }
}