export const adminTypes = {
    adminAddTrait: '[admin] add a new trait',
    adminUpdateTrait: '[admin] modifies an existing trait',

    adminTraitFilterAdd: '[admin] add a new trait filter',
    adminTraitFilterRemove: '[admin] removes a trait filter',

    adminSetArtAmount: '[admin] loads the amount of generated art',

    adminSetAnimal: '[admin] sets the animal for the admin panel'
}