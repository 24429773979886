import { apiGetSounds } from "utils/api"
import { getBase64FromUrl } from "utils/base64";
import { logError } from "utils/errors";
import { soundTypes } from "./soundTypes"

export const loadSounds = (animal) => {
    return async (dispatch) => {
        try {
            const result = await apiGetSounds(animal);
            if (!result.ok){
                throw new Error('Unable to load sounds. API Error. Error: ' + result.message)
            }
            const {message} = result;
            dispatch(setSounds(message)) 
        } catch ( error ) {
            logError('Loading sounds', error, 'na');
        }
    }
}

const setSounds = (sounds) => ({
    type: soundTypes.soundGet,
    payload: sounds
})




export const setSelectedSounds = (sound = []) => ({
    type: soundTypes.soundSelect,
    payload: sound
})

const addSoundToQueue = (sound) => ({
    type: soundTypes.soundAddQueue,
    payload: sound
})

const removeSoundFromQueue = () => ({
    type: soundTypes.soundRemoveQueue
})



export const setNextSound = () => {
    return async (dispatch, getState) => {
        try {
            const {sounds, queue} = getState().sound;
    
            if (sounds.length===0)
                return;
            if (queue?.length === 0)
                return;
            
            const sound = queue[0];
            await dispatch(setSelectedSounds(sound));
            await dispatch(removeSoundFromQueue());
            dispatch(addToQueue());
        } catch ( error ) {
            console.log(error);
        }
        
        
    }
}

export const addToQueue = () => {
    return async (dispatch, getState) => {
        const {sounds} = getState().sound;
        console.log('adding into queue' + sounds.length);
        let sound = await getRandomSound(sounds)
        if (sound){
            console.log('adding into queue: ' + sound);
            await dispatch(addSoundToQueue(sound));
        }
        else {
            console.log('Unable to add sound into queue.');
        }
    }
}

const getRandomSound = async (sounds) => {
    // we get the number of sound types we will use
    if (sounds.length == 0)
        return;

    try {
        const selectedSound = []
        for (let i = 0; i<4; i++){
            const instrumentIndex = randomIntFromInterval(0, sounds.length-1);
            const selectedInstrument = sounds[instrumentIndex];
            const instrumentSounds = selectedInstrument.sounds;
            if (instrumentSounds.length > 1){
                const soundtIndex = randomIntFromInterval(0, instrumentSounds.length-1);
                const base64 = await getBase64FromUrl(instrumentSounds[soundtIndex].url);
                selectedSound.push({instrument: selectedInstrument.instrument, sound: instrumentSounds[soundtIndex].filename, url: instrumentSounds[soundtIndex].url, base64})
                
                const isSuperSequence = selectedSound.find(val => val.instrument === 'Super Sequence');
                if (isSuperSequence)
                    return [isSuperSequence];
            } else {
                const base64 = await getBase64FromUrl(instrumentSounds[0].url);
                selectedSound.push({instrument: selectedInstrument.instrument, sound: instrumentSounds[0].filename, url: instrumentSounds[0].url, base64})
            }
        }
    
        const isSuperSequence = selectedSound.find(val => val.instrument === 'Super Sequence');
        if (isSuperSequence){
            return [isSuperSequence];
        } else {
            const uniqueSounds = selectedSound.filter((value, index, self) =>
                index === self.findIndex((t) => (
                t.instrument === value.instrument
                ))
            )
            return uniqueSounds;
        }
    } catch ( error ){
        console.log(error);
        return null;
    }
}

function randomIntFromInterval(min, max) { // min and max included 
    return Math.floor(Math.random() * (max - min + 1) + min)
  }