import { soundTypes } from "./soundTypes"

const initialState = {
    sounds: [],
    queue: [],
    selected: []
}

export const soundReducer = (state = initialState, action) => {
    switch(action.type) {
        case soundTypes.soundGet:
            return {
                ...state,
                sounds: action.payload
            }

        case soundTypes.soundSelect:
            return{
                ...state,
                selected: action.payload
            }
        
        case soundTypes.soundAddQueue:
            return {
                ...state,
                queue: [...state.queue, action.payload]
            }

        case soundTypes.soundRemoveQueue:
                return {
                    ...state,
                    queue: state.queue.slice(1)
                }

        default:
            return state;
    }
}