import { localStorageAdd } from "utils/localStorage";
import { uiTypes } from "./uiTypes";


export const switchLanguage = (language) => {
    return (dispatch) => {
        localStorageAdd('safariSurfers', {language})
        dispatch(setLanguage(language));
    }
}

const setLanguage = (language) => ({
    type: uiTypes.uiSetLanguage,
    payload: language
})

export const uiSetAdminActiveLink = (id) => ({
    type: uiTypes.uiSetAdminActiveLink,
    payload: id
})

export const uiCloseAlert = () => ({
    type: uiTypes.uiCloseAlert
})

export const uiShowAlert = (severity, title, message) => ({
    type: uiTypes.uiOpenAlert,
    payload: {severity, title, message}
})

export const uiShowBackdrop = () => ({
    type: uiTypes.uiOpenBackdrop
})

export const uiCloseBackdrop = () => ({
    type: uiTypes.uiCloseBackdrop
})

