import {mintTypes} from './mintTypes';

const initialState = {
    prices: {
        each: '100000000000000',
        withPhysical: '10000000000000',
        selectAnimal: '50000000000000',
        usd: 1300.03,
        lastUpdate: 112312312321
    },
    step1: null,
    step2: null,
    terms: false,
    total: '0'
}

export const mintReducer = (state = initialState, action) => {
    switch (action.type) {
        
        case (mintTypes.setPrices): 
            return {
                ...state,
                prices: {
                    ...state.prices,
                    each: action.payload.each,
                    selectAnimal: action.payload.selectAnimal,
                    withPhysical: action.payload.withPhysical,
                }
            }


        case (mintTypes.setUSD): 
            return {
                ...state,
                prices: {
                    ...state.prices,
                    usd: action.payload.usd,
                    lastUpdate: action.payload.lastUpdate
                }
            }

        case (mintTypes.setStep1): 
            return {
                ...state,
                step1: action.payload
            }

        case (mintTypes.setStep2): 
            return {
                ...state,
                step2: action.payload
            }

        case (mintTypes.setTerms): 
            return {
                ...state,
                terms: action.payload
            }

        case (mintTypes.setTotal): 
            return {
                ...state,
                total: action.payload
            }
        default:
            return state
    }
}