import React, { useEffect } from 'react'
import {
    BrowserRouter as Router,
    Switch,
    Route
  } from "react-router-dom";

import WithLayout from './WithLayout';
import {
  Main as MainLayout,
  Fluid as FluidLayout,
  Fixed as FixedLayout,
} from 'layouts';
import Home from 'views/Home';
import { Art } from 'views/Art';
import { FullScreen } from 'layouts/FullScreen';
import { Admin } from 'views/Admin';
import { Mint } from 'views/Mint';
import { NFT } from 'views/NFT';
import { Buy } from 'views/Buy';
import { TermsOfService } from 'views/TermsOfService';
import { PrivacyPolicy } from 'views/PrivacyPolicy';
import { CommingSoon } from 'views/CommingSoon/CommingSoon';
import { RedirectToDrop } from 'views/RedirectToDrop';

export const AppRoutes = () => {
  const buyNowEnabled = (process.env.BUY_NOW_ENABLED === 'true' ? true : false)
  return (
    <div>
          <Switch>
            <Route
                exact
                path="/"
                render={(matchProps) => (
                  <WithLayout
                    {...matchProps}
                    component={RedirectToDrop}
                    layout={FullScreen}
                  />
                )}
              />
            <Route
              exact
              path="/nft/:tokenId"
              render={(matchProps) => (
                <WithLayout
                  {...matchProps}
                  component={NFT}
                  layout={FullScreen}
                />
              )}
            />
              {/* <Route
                exact
                path="/"
                render={(matchProps) => (
                  <WithLayout
                    {...matchProps}
                    component={CommingSoon}
                    layout={FullScreen}
                  />
                )}
              />

              <Route
                exact
                path="/private"
                render={(matchProps) => (
                  <WithLayout
                    {...matchProps}
                    component={Home}
                    layout={MainLayout}
                  />
                )}
              />

              <Route
                exact
                path="/art/:animal/:artId"
                render={(matchProps) => (
                  <WithLayout
                    {...matchProps}
                    component={Art}
                    layout={FullScreen}
                  />
                )}
              />

              

              <Route
                exact
                path="/terms"
                render={(matchProps) => (
                  <WithLayout
                    {...matchProps}
                    component={TermsOfService}
                    layout={MainLayout}
                  />
                )}
              />

              <Route
                exact
                path="/privacy"
                render={(matchProps) => (
                  <WithLayout
                    {...matchProps}
                    component={PrivacyPolicy}
                    layout={MainLayout}
                  />
                )}
              />


              {(buyNowEnabled) && (
                <Route
                  exact
                  path="/buy"
                  render={(matchProps) => (
                    <WithLayout
                      {...matchProps}
                      component={Buy}
                      layout={MainLayout}
                    />
                  )}
                />
              )}

              <Route
                exact
                path="/mint"
                render={(matchProps) => (
                  <WithLayout
                    {...matchProps}
                    component={Mint}
                    layout={FluidLayout}
                  />
                )}
              />


              <Route
                path="/admin"
                render={(matchProps) => (
                  <WithLayout
                    {...matchProps}
                    component={Admin}
                    layout={FluidLayout}
                  />
                )}
              /> */}
          </Switch>
    </div>
  )
}
