import { uiTypes } from "./uiTypes"

const initialState = {
    language: 'EN',
    adminActiveLink: 'artReview',
    alert: {
        open: false,
        severity: 'success',
        message: '',
        title: ''
    },
    backdrop: false,
}

export const uiReducer = (state = initialState, action) => {
    switch(action.type) {
        case uiTypes.uiSetLanguage:
            return {
                ...state,
                language: action.payload
            }

            // shows the alert message
        case uiTypes.uiOpenAlert:
            return ({
                ...state,
                alert: {open: true, severity: action.payload.severity, title: action.payload.title, message: action.payload.message}
            })

        // closes the alert message
        case uiTypes.uiCloseAlert:
            return ({
                ...state,
                alert: {...state.alert, open: !state.alert.open}
            })

        case uiTypes.uiOpenBackdrop:
            return {
                ...state,
                backdrop: true
            }

        case uiTypes.uiCloseBackdrop:
            return {
                ...state,
                backdrop: false
            }

        case uiTypes.uiSetAdminActiveLink:
            return {
                ...state,
                adminActiveLink: action.payload
            }

        default:
            return state;
    }
}