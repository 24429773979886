import React, { useEffect } from 'react'
import { useHistory } from "react-router-dom"

export const RedirectToDrop = () => {
    const history = useHistory()

    useEffect(() => {
        history.push('https://opensea.io/collection/safarisurfers/drop')
    }, [])
    
  return (
    <div>
        Redirecting...
    </div>
  )
}
