import moment from 'moment';

import {db} from 'config/firebase';

export const logError = async (action, error, uid) => {
    try {
        const data = {
            action: (action) ? action : 'Unkown', 
            number: (error?.number) ? error.number : 0 ,
            name: error?.name,
            message: error?.message,
            lineNumber: (error?.lineNumber) ? error.lineNumber : 0,
            stack: error?.stack.toString(),
            description: error?.toString(),
            uid: (uid) ? uid : 'none'
        }
        console.log(data);
        
        const now = moment().unix();
        await db.collection('errors').doc(now.toString()).set(data)
    } catch ( error ) {
        // couldn't store the error, not much left to do.
    }
}