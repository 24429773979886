import { CustomAlert } from 'common/CustomAlert';
import React from 'react'
import { Provider } from 'react-redux';
import { createBrowserHistory } from 'history';
import { Router } from 'react-router-dom';
import { AppRoutes } from 'routes/AppRoutes'
import { store } from 'store/store';
import 'SafariSurfClub.css'
import { CustomBackDrop } from 'common/CustomBackDrop';
const browserHistory = createBrowserHistory();

export const SafariSurfClub = () => {
  return (
    <div>
      <Provider store={store}>
        <Router history={browserHistory}>
          <AppRoutes/>
        </Router>
        
        <CustomAlert/>
        <CustomBackDrop/>
      </Provider>
    </div>
  )
}
    