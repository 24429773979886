import {authTypes} from './authTypes';

const initialState = {
    uid: null
}

export const authReducer = (state = initialState, action) => {
    switch(action.type) {

        // sets an authenticated user
        case authTypes.setUser:
            return {
                ...state,
                uid: action.payload
            }

        // stes and authenticated user
        case authTypes.signupUser:
            return {
                ...state,
                uid: action.payload
            }

        // removes the authenticated user
        case authTypes.logOutUser:
            return {
                ...state,
                uid: null
            }
        default:
            return state;
    }
}